import React from 'react'
import { Helmet } from "react-helmet"
import { withPrefix, graphql, StaticQuery } from "gatsby"
import Layout from '../components/layout'
// import Container from '../components/container'
import Metatags from "../components/Metatags"
import InnerHTML from 'html-react-parser'
// require('dotenv').config()
import './../assets/elementor.scss';
import Image from '../components/Elementor/Img'


const components = {
  Image: Image
}

function ucwords(text) {
  let str = text.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
    function (s) {
      return s.toUpperCase();
    }
  )
}

const HomePage = () => {
  return (
    <React.Fragment>
      <StaticQuery
        query={HomePageQuery}
        render={data => {
          // console.log(data);
          // const elementorContent = data.wpPage.elementorContent;
          // const elementorData = JSON.parse(data.wpPage.elementorData);
          // console.log(elementorData);
          // const Page = elementorData.map(row =>{
          //   return(
          //     <div key={row.id} className="row">
          //       {
          //         row.elements.map(column =>{
          //           return(
          //             <div key={column.id} className={`col-${column.settings._column_size}`}>
          //               {
          //                 column.elements.map(widget =>{
          //                   if(widget.elType == "widget"){
          //                     console.log(widget);
          //                     return(
          //                       React.createElement(
          //                         components[ucwords(widget.widgetType)],
          //                         {
          //                           ...widget.settings,
          //                           key: widget.id
          //                         }
          //                       )
          //                     )
          //                   }
          //                   else{


          //                     return
          //                       widget.elements.map(wid => {
          //                         return (
          //                           <div key={widget.id} className={`col-${widget.settings._column_size}`}>
          //                             {
          //                               React.createElement(
          //                                 components[wid.widgetType],
          //                                 {
          //                                   ...wid.settings,
          //                                   key: wid.id
          //                                 }
          //                               )
          //                             }
          //                           </div>

          //                       )
          //                       })
          //                   }
          //                 })
          //               }
          //             </div>
          //           )
          //         })
          //       }
          //     </div>
          //   )
          // });
          return (
            <Layout className="home-page">
                <Metatags
                    title="Goal-Based Popup Builder | Powerful Lead Generation Tool"
                    description = "Start creating lead capturing forms and exit intent popups for your website. Drive sales and conversions, reduce cart abandonment, grow your email list and do more using multiple popup form types and pre-existing popup templates."
                    keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
                    url = "https://www.optinly.com"
                    pathname = "/"
                />
                <Helmet title={data.wpPage.title}>
                  <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.css" />
                  <link rel="stylesheet" href="https://unpkg.com/swiper/swiper-bundle.min.css" />

                  <script src="https://unpkg.com/swiper/swiper-bundle.js"></script>
                  <script src="https://unpkg.com/swiper/swiper-bundle.min.js"></script>

                </Helmet>
              {/* <Helmet title={data.wpPage.title}>
                <link href={`https://content.optinly.net/wp-content/uploads/elementor/css/post-${data.wpPage.databaseId}.css`} rel="stylesheet" type="text/css" />
              </Helmet> */}
              {/* {<InnerHTML html={elementorContent} />} */}
              {InnerHTML(data.wpPage.content)}
              {/* {Page} */}
            </Layout>
          )
        }}
      />
    </React.Fragment>
  )
}

const HomePageQuery = graphql`
query HomePageQuery {
  wpPage(id: {eq: "cG9zdDo3"}) {
    id
    databaseId
    title
    date
    uri
    content
  }
}
`

export default HomePage
